<template>
  <div class="page">
    <div class="mainContainer">
      <TopMenu :show-logo="true"/>
      <!--<img src="../assets/images/logo.png" class="centerLogo"/>-->
      <div class="catsArea">
        <DuList type="cats" :style="{ flexDirection: 'row', display: 'flex', 'flex-wrap': 'wrap' }" :exstra-parameters="{...state.extraParams}">

        </DuList>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import DuList from "../components/DuList";
import {onBeforeMount, onMounted, reactive, watch} from "vue";
import BrowserHelper from "../helpers/BrowserHelper";
import {useRoute, useRouter} from "vue-router";
export default {
  name: "Categories",
  components: {DuList, Footer, TopMenu},
  setup() {
    const state = reactive({
      extraParams: {},
    });

    const route = useRoute();
    onBeforeMount(() => {
      buildParams();
    });

    watch(() => route.name, () => {
      buildParams();
    });

    const buildParams = () => {
      if(route.name === "areas") {
        state.extraParams.type = 'area';
        BrowserHelper.changeTitle('المناطق');
      } else if(route.name === "categories") {
        state.extraParams.type = 'cat';
        BrowserHelper.changeTitle('التصنيفات');
      }
    }

    return {
      state,
    };
  }
}
</script>

<style scoped>
.page {
  background-color: #000;
  width: 100%;
  background-image: url("../assets/images/bg.jpg");
  background-size: cover;
  min-height: 100%;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
}
.catsArea{
  display: flex;
  width: available;
  place-content: center;
  margin-top: 50px;
}
.centerLogo{
  width: 200px;
  place-self: center;
}
.fixedLogo{
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  margin: -10px 100px;
  background-image: url("../assets/images/fixedLogo.png");
  background-size: auto 100%;
  background-position: center;
}

@media only screen and (max-width: 600px) {
  .page {
    background-attachment: scroll;
  }
  .fixedLogo {
    display: none;
  }
}
</style>